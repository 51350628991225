/*@import url(https://fonts.googleapis.com/css?family=Great+Vibes);*/
@import url(https://fonts.googleapis.com/css?family=PT+Sans);
@import url(https://fonts.googleapis.com/css?family=Kalam);
@import url(https://fonts.googleapis.com/css?family=Qwigley);
@import url(https://fonts.googleapis.com/css?family=Satisfy);

@font-face {
    font-family: "CloisterBlack";
    src: url("/fonts/CloisterBlack.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

body {
    background-color: #fdd;
    background-image: linear-gradient(
            to bottom,
            rgba(255, 150, 150, 0.9) 0%,
            rgba(255, 230, 230, 0.8) 100%
        ),
        url(/fondo.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top center;
    background-size: 100%;
    margin: 0;
    padding: 8px;
}

.row {
  margin-left: -8px;
  margin-right: -8px;
}

.section {
    margin: 0 40px;
    padding: 10px 10px;
    text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: none;
    font-family: "CloisterBlack", sans-serif;
    font-weight: 500;
    color: #fff;
}

h1 {
    padding-top: 0px;
}

h2 sub,
h3 sub {
    font-family: "Kalam", cursive !important;
    font-size: 12px !important;
}

h2 {
    font-size: 40px;
    padding-left: 20px;
}

h3 {
    margin-top: 0px;
    overflow: hidden;
    display: block;
    width: 100%;
    line-height: 20px;
    font-size: 16px;
}

p,
input,
textarea,
select {
    font-family: "PT Sans", sans-serif;
    /*font-style: italic;*/
    color: #200;
    padding: 10px;
}

img.img-thumbnail {
    margin-top: 10px;
}

a, .link {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    transform: scale(1);
    text-decoration: none !important;
    color: #300;
}

a:hover, .link:hover {
    opacity: 1;
    transform: scale(1.1);
    text-decoration: none !important;
    background-color: rgba(255, 230, 230, 0.7);
    border-radius: 10px;
    color: #933;
}

a:active, .link:active {
    text-decoration: none !important;
}

footer {
    background: #fff;
    color: #999;
    padding: 20px;
    font-size: 12px;
    text-align: center;
}

img.img-thumbnail {
    overflow: hidden;
    display: block;
    margin: 0 auto;
    text-align: center;
}

img.img-thumbnail.floating {
    float: left;
    width: 100px;
    margin: 15px;
    margin-right: 20px;
}

.not-visible {
    opacity: 0;
}

.welcome {
    font-size: 24px !important;
    font-family: "Satisfy";
    line-height: normal;
    opacity: 0.8;
}

.letrafea {
    font-family: "PT Sans";
    color: #00f;
}

.rojo {
    color: #f00;
}

.description {
    min-height: 160px;
    font-size: 15px;
}

.repeat-animated.ng-enter-stagger {
    transition-delay: 0.3s;
    transition-duration: 0s;
    animation-delay: 0.3s;
    animation-duration: 0s;
}

.repeat-animated.ng-enter-stagger,
.repeat-animated.ng-leave-stagger,
.repeat-animated.ng-move-stagger {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-transition-duration: 0;
    transition-duration: 0;
}

.repeat-animated.ng-enter {
    -webkit-transition: 0.2s linear all;
    transition: 0.2s linear all;
    opacity: 0;
    -webkit-transform: scale(1.15) !important;
    transform: scale(1.15) !important;
}

.repeat-animated.ng-enter.ng-enter-active {
    opacity: 1;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}
.repeat-animated.ng-leave {
    -webkit-transition: 0.1s linear all;
    transition: 0.1s linear all;
    opacity: 1;
}
.repeat-animated.ng-leave.ng-leave-active {
    opacity: 0;
}

.ngdialog.ngdialog-theme-default {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
    width: 350px !important;
}

.modal-dialog {
    min-width: 330px !important;
}

.grid .image-border {
    box-shadow: 10px 10px 19px 0px rgba(200, 0, 0, 0.1),
    1px 11px 13px 0 rgba(200, 0, 0, 0.19);
    max-height: 200px;
    overflow: hidden;
    min-height: 60px;
    margin: 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.grid img {
    text-align: center;
    width: 100%;
    -webkit-transition: width 0.2s, height 0.2s, margin 0.2s; /* Safari */
    transition: width 0.2s, height 0.2s, margin 0.2s;
}

.grid img:hover {
    padding: 0px !important;
    width: 150%;
    margin-left: -25%;
}

.grid h3 {
  margin: 0 auto;
  text-align: center;
}

.grid {
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
}

.margintitle {
    text-decoration: none;
}

.social .title {
  font-size: 22px
}

.clearfix {
  clear: both;
}

.whiteback {
    border-radius: 10px;
    padding: 30px;
    background-color: #FFEEEE66;
    margin: 20px;
}

.whiteback h3 {
    color: #333;
}

.whiteback p {
    color: #666;
    font-size: 15px;
    text-align: justify;
}

.whiteback sub {
    font-size: 50%;
    font-family: 'Lora', sans-serif;
}

.nav-pills {
    font-family: "CloisterBlack", sans-serif;
    font-size: 19px;
}

.nav-pills .nav-link {
    background-color: #fd707021;
    margin: 0px 3px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #c31b1b;
}

@media only screen and (min-width: 320px) {
    h1 {
        font-size: 55px;
        text-align: center;
    }
    .welcome {
        font-size: 16px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
    h1 {
        font-size: 55px;
        text-align: center;
    }
    .welcome {
        font-size: 16px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
    h1 {
        font-size: 55px;
        padding-left: 20px;
        text-align: left;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    h1 {
        font-size: 65px;
        padding-left: 20px;
        text-align: left;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
    h1 {
        font-size: 70px;
        padding-left: 20px;
        text-align: left;
    }
}

.col-xs-4 {
    position: relative;
    width: 32%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-6 {
    position: relative;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}

.img-loading {
    opacity: 0;
}

.img-loaded {
    opacity: 1;
    animation: fadeIn 1s ease;
}

@keyframes fadeIn{
    0%{
    opacity: 0;
    }
    100%{
    opacity: 1;
    }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}